const REQUEST = "marketplace/allOrders/REQUEST";
const SUCCESS = "marketplace/allOrders/SUCCESS";
const FAILURE = "marketplace/allOrders/FAILURE";

const initialState = { data: [], more: undefined };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload
      };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getAllOrders = event => async (dispatch, getState) => {
  dispatch({ type: REQUEST });
  let claims = [];
  let hasMore = true;
  let after = "";
  while (hasMore) {
    const results = await getState().apis.marketplace.get("/claims", {
      params: {
        eventSlug: event,
        after
      }
    });
    const { data } = results;
    claims = claims.concat(data.claims);
    hasMore = data.hasMore;
    after = data.continuationToken;
  }
  return dispatch({ type: SUCCESS, payload: claims });
};
