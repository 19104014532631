/* eslint-disable no-undef */
export const theme = {
  NAME: "blackin",
  TENANT: "pyxu5a",
  LINKS: [
    {
      url: "https://www.trywinwin.com/tournaments",
      label: "Black-IN Blacktop Tournament"
    },
    {
      url: "https://www.blackinfestival.com",
      label: "Black-IN Freedom Festival"
    }
  ],
  SOCIAL:
    "https://www.instgram.com/trywinwin https://www.twitter.com/win_win https://www.facebook.com/trywinwin https://www.linkedin.com/company/win-win",
  HEADING_1: "Win-Win",
  HEADING_2: "Gamified Giving™ is transforming philanthropic fundraising."
};
