/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "utculturecenter",
  TENANT: "5sy34q",
  // External header links
  LINKS: [],
  // Social media links for footer
  SOCIAL: "",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "",
  HEADING_2: "UT Culture Center Digital Series:",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: false,
  DEMO: false
};
