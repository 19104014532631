const REQUEST = "marketplace/highlights/REQUEST";
const SUCCESS = "marketplace/highlights/SUCCESS";
const FAILURE = "marketplace/highlights/FAILURE";

const initialState = { data: undefined, isFetching: true };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, errorMessage: action.payload };
    default:
      return state;
  }
}

export const getHighlights = after => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get("/records", {
      params: {
        highlighted: true,
        endsAfterDateTime: after
      }
    })
    .then(results => {
      dispatch({ type: SUCCESS, payload: results.data.records });
    })
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
